import React, { useState } from "react";

import { Box, Collapse, IconButton, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";

import { ColorModeContext } from "./ThemeWrapper";

function ThemeSwitch() {
  const theme = useTheme();
  const [hover, setHover] = useState(false);
  const colorMode = React.useContext(ColorModeContext);
  console.log("Theme pallete mode", theme.palette.mode);
  console.log("colorMode mode in context", colorMode);

  return (
    <Box
      onMouseEnter={() => {
        console.log("Hovering");
        setHover(true);
      }}
      onMouseLeave={() => {
        console.log("Not Hovering");
        setHover(false);
      }}
      sx={{
        display: "flex",
        width: "fit-content",
        alignItems: "center",
        justifyContent: "center",
        // bgcolor: "background.default",
        color: "text.primary",
        borderRadius: 1,
        // p: 3,
        position: "fixed",
        top: "20px",
        right: "20px",
        zIndex: 11,
      }}
    >
      <Collapse in={hover} unmountOnExit sx={{ textTransform: "capitalize" }}>
        <Typography
          sx={{
            fontSize: "1.1rem",
            backgroundColor: "#3377cc",
            padding: "5px",
            borderRadius: 3,
            fontWeight: "bold",
          }}
        >
          {theme.palette.mode} Mode
        </Typography>
      </Collapse>

      <IconButton onClick={colorMode.toggleColorMode} color="inherit">
        {theme.palette.mode === "dark" ? (
          <Brightness7Icon />
        ) : (
          <Brightness4Icon />
        )}
      </IconButton>
    </Box>
  );
}

export default ThemeSwitch;
