import { Typography } from "@mui/material";
import React from "react";
import TypeWriterComponent from "../typewriter/TypeWriterComponent";
import homeStyles from "./home-component.module.css";
const HomeComponent = () => {
  return (
    <div className={homeStyles.container}>
      <Typography variant="h4" sx={{ color: "#fff" }}>
        <em>Hey, I'm</em>
      </Typography>
      <Typography
        className={homeStyles.myName}
        sx={{
          color: "#fff",
          textShadow:
            "2px 7px 5px rgba(0,0,0,0.3), 0px -4px 10px rgba(255,255,255,0.3)",
          // textShdow:
          //   "3px 0px 7px rgba(81,67,21,0.8), -3px 0px 7px rgba(81,67,21,0.8), 0px 4px 7px rgba(81,67,21,0.8)",
        }}
      >
        {/* <TypeWriterComponent text={"David Secul"} /> */}
        David Secul
      </Typography>
    </div>
  );
};

export default HomeComponent;
