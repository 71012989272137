import { Button, IconButton } from "@mui/material";
import React from "react";
import scrollButtonStyles from "./section-scroller.module.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

const SectionScroller = ({ handleUp, handleDown }) => {
  return (
    <div className={scrollButtonStyles.btn__container}>
      <IconButton
        sx={{
          backgroundColor: "#3377cc",
          borderRadius: 2,
          "&:hover": {
            backgroundColor: "#3377cc",
            filter: "brightness(150%)",
          },
        }}
        className={scrollButtonStyles.btn}
        variant="filled"
        onClick={handleUp}
      >
        <ArrowDropUpIcon style={{ fill: "#fff" }} />
      </IconButton>
      <IconButton
        sx={{
          backgroundColor: "#3377cc",
          borderRadius: 2,
          "&:hover": {
            backgroundColor: "#3377cc",
            filter: "brightness(150%)",
          },
        }}
        className={scrollButtonStyles.btn}
        variant="filled"
        onClick={handleDown}
      >
        <ArrowDropDownIcon style={{ fill: "#fff" }} />
      </IconButton>
    </div>
  );
};

export default SectionScroller;
