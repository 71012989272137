import { useTheme } from "@emotion/react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import coreStyles from "./core-component.module.css";
import DoneAllIcon from "@mui/icons-material/DoneAll";

const CoreComponent = () => {
  const theme = useTheme();
  const system = [
    "Focus On Happiness, Not Money",
    "Be inventive, lovable, systematic, and unyielding.",
    "Have some fun with it: Don't Take Yourself Too Seriously",
    "Every Day I Learn",
    "Believe in Change and adapt",
    "Prioritize mental and physical health, your family and friends... And always the customer",
    "Invest in ideas that create positive change",
  ];
  function createHex() {
    var hexCode1 = "";
    var hexValues1 = "0123456789a";

    for (var i = 0; i < 6; i++) {
      hexCode1 += hexValues1.charAt(
        Math.floor(Math.random() * hexValues1.length)
      );
    }
    return hexCode1;
  }

  function generateGradient() {
    var deg = Math.floor(Math.random() * 360);
    var gradient =
      "linear-gradient(" +
      deg +
      "deg, " +
      "#" +
      createHex() +
      ", " +
      "#" +
      createHex() +
      ")";

    return gradient;
  }

  return (
    <Box className={coreStyles.container}>
      <Box className={coreStyles.heading__container}>
        <Box
          className={coreStyles.line}
          sx={{ backgroundColor: theme.palette.background }}
        />
        <Typography variant="h2" className={`${coreStyles.heading}`}>
          My Core
        </Typography>

        <Box className={coreStyles.line} />
      </Box>
      <Container maxWidth="lg" className={coreStyles.list}>
        {system.map((each, idx) => {
          return (
            <Box
              key={idx}
              sx={{
                width: "100%",
                maxWidth: "700px",
                // backgroundColor: "yellow",
                textAlign: "left",
                padding: "20px",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                background:
                  theme.palette.mode === "dark"
                    ? generateGradient()
                    : generateGradient(),
                marginBottom: "10px",
                borderRadius: 2,
                boxShadow: 3,
                transition: "all 250ms linear",
                "&:hover": {
                  transform: "scale(1.1)",
                  cursor: "pointer",
                },
              }}
            >
              <DoneAllIcon style={{ fill: "#fff", fontSize: "50px" }} />
              <Typography className={coreStyles.text_core}>{each}</Typography>
            </Box>
          );
        })}
      </Container>
    </Box>
  );
};

export default CoreComponent;
