import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";

import React, { useEffect } from "react";
import "./footer-component.css";

const FooterComponent = () => {
  const theme = useTheme();

  const scrollToSection = (id) => {
    const divElement = document.getElementById(id);
    divElement.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <footer className="footer-distributed">
      <div className="footer-left">
        <h3>
          David<span>Secul</span>
        </h3>
        {/* home
journey
project
life
core
footer */}
        <p className="footer-links">
          <Button
            id="navBtn"
            value="home"
            onClick={(e) => scrollToSection(e.target.value)}
          >
            Home
          </Button>

          <Button
            id="navBtn"
            value="journey"
            onClick={(e) => scrollToSection(e.target.value)}
          >
            Journey
          </Button>

          <Button
            id="navBtn"
            value="project"
            onClick={(e) => scrollToSection(e.target.value)}
          >
            Projects
          </Button>

          <Button
            id="navBtn"
            value="life"
            onClick={(e) => scrollToSection(e.target.value)}
          >
            Life
          </Button>

          <Button
            id="navBtn"
            value="core"
            onClick={(e) => scrollToSection(e.target.value)}
          >
            Core
          </Button>
        </p>

        <p className="footer-company-name">
          Copyright 2022. All rights reserved!
        </p>
      </div>

      <div className="footer-center">
        <div>
          <i className="fa fa-map-marker"></i>
          <p>
            <span>1668 Amy ct,</span>Toms River,<span>NJ 08755</span>
          </p>
        </div>

        <div>
          <i className="fa fa-phone"></i>
          <p>(917) 312-3305</p>
        </div>

        <div>
          <i className="fa fa-envelope"></i>
          <p>
            <a href="mailto:support@company.com"> davidsecul@me.com</a>
          </p>
        </div>
      </div>

      <div className="footer-right">
        <p className="footer-company-about">
          <span>About David</span>
          He is a US-Based Entrepreneur, Multihyphenate Creator, And Innovator
          With Goal Oriented Vision Prioritizing The Customer And Relationship
          Focusing On Movements That Generate Resilience, Self-Sustainability,
          And Dynamism.
        </p>

        <div className="footer-icons">
          <a
            href="https://www.linkedin.com/in/david-s-b938394/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedInIcon style={{ fill: "#0077b5" }} />
          </a>
          <a
            href="https://www.instagram.com/thisismyylife2020/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramIcon style={{ fill: "#8a3ab9" }} />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;
