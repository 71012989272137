import { Box, Button, Typography } from "@mui/material";
import React from "react";
import projectCardStyles from "./card-component.module.css";
const ProjectCard = ({ data }) => {
  console.log(data);
  function createMarkup({ html }) {
    return { __html: html };
  }
  return (
    <div className={projectCardStyles.wrapper}>
      <div
        className={projectCardStyles.card}
        style={{
          background: `linear-gradient(
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.4)
      ), url(${data.bg})`,
          backgroundPosition: "50% 50%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        {/* <img className={projectCardStyles.image} src={data.image} /> */}

        <Hexagon
          image={data.image}
          filter={
            data.heading === "IntelligeneCG"
              ? true
              : data.heading === "iMerchandise"
              ? true
              : false
          }
          heading={data.heading}
        />
        <div className={projectCardStyles.info}>
          <div>
            <div
              id={`${
                data.heading.length < 20
                  ? projectCardStyles["heading"]
                  : projectCardStyles["heading__sm"]
              }`}
            >
              {data.heading}
            </div>
            <p
              id={`${
                data.description.length < 150
                  ? projectCardStyles["intro"]
                  : projectCardStyles["intro__sm"]
              }`}
            >
              {data.intro}
            </p>
          </div>
          <div
            id={`${
              data.description.length < 150
                ? projectCardStyles["desc"]
                : projectCardStyles["desc__sm"]
            }`}
            dangerouslySetInnerHTML={{ __html: data.description }}
          ></div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {/* {data.heading ===
            "Vendor Compliance Handbook" ? null : data.heading ===
              "Vendor Compliance Handbook" ? null : (
              <img
                src={data.image}
                alt={data.heading}
                style={{ width: "100px", objectFit: "contain" }}
              />
            )} */}
            <img
              src={data.image}
              alt={data.heading}
              style={{ width: "100px", objectFit: "contain" }}
            />
            {/* {data.heading === "Vendor Compliance Handbook"  ? 
              null
             : data.heading === "Gravity Rope"?null:<img
              src={data.image}
              alt={data.heading}
              style={{ height: "60px", objectFit: "contain" }}
            />)} */}

            <button
              id={projectCardStyles["button__style"]}
              onClick={(e) => {
                window.open(`${data.link}`, "__blank");
              }}
            >
              Visit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;

const Hexagon = ({ image, filter, heading }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: "30%",
        left: 0,
        width: "100%",
        padding: "0px 20px",
        filter: "drop-shadow(0px 10px 12px rgba(0, 0, 0, 1))",
      }}
    >
      <Box
        className={projectCardStyles.hex}
        // sx={{
        //   "&:hover": {
        //     backgroundColor: "yellow",
        //   },
        // }}
      >
        <img
          src={image}
          style={{
            filter:
              filter && heading !== "iMerchandise"
                ? "invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%)"
                : filter && heading === "iMerchandise"
                ? "drop-shadow(2px 0px 0px #87CEEB) drop-shadow(-2px 0px 0px #87CEEB)"
                : " none",
          }}
        />
      </Box>
    </Box>
  );
};
