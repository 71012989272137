import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import appStyles from "./App.module.css";
import { Box, CircularProgress, Container } from "@mui/material";
import SectionScroller from "./components/section-scroller/SectionScroller";
import HomeComponent from "./components/home-component/HomeComponent";
import ThemeSwitch from "./theme/ThemeSwitch";
import JourneyComponent from "./components/journey-component/JourneyComponent";
import ProjectComponent from "./components/project-component/ProjectComponent";
import LifeComponent from "./components/life-component/LifeComponent";
import CoreComponent from "./components/core-component/CoreComponent";
import AboutComponent from "./components/about-component/AboutComponent";
import { useTheme } from "@emotion/react";
import HobbiesComponent from "./components/hobbies-component/HobbiesComponent";
import FooterComponent from "./components/footer-component/FooterComponent";

function App() {
  const [currentSectionId, setCurrentSectionId] = useState(0);
  const [journeyData, setJourneyData] = useState([]);
  const [lifeData, setLifeData] = useState([]);
  const [projectsData, setProjectsData] = useState([]);
  const [loading, setLoading] = useState(true);

  const theme = useTheme();
  const getData = () => {
    fetch("data.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        // console.log(myJson);
        setLoading(false);
        setJourneyData(myJson?.journey);
        setLifeData(myJson?.life);
        setProjectsData(myJson?.projects);
      })
      .catch((err) => {
        console.log("Error while loading data", err);
      });
  };
  useEffect(() => {
    getData();
  }, []);

  const sectionArray = ["home", "journey", "project", "life", "core", "footer"];
  function handleScrollDown() {
    console.log("Clicked down");

    if (currentSectionId === sectionArray.length - 1) {
      setCurrentSectionId(0);
    } else {
      setCurrentSectionId(currentSectionId + 1);
    }
  }
  function handleScrollUp() {
    console.log("Clicked Up");

    if (currentSectionId === 0) {
      setCurrentSectionId(sectionArray.length - 1);
    } else {
      setCurrentSectionId(currentSectionId - 1);
    }
  }
  useEffect(() => {
    // document.getElementById("id").scrollIntoView();
    if (!loading) {
      console.log("Current Section is", sectionArray[currentSectionId]);
      const divElement = document.getElementById(
        sectionArray[currentSectionId]
      );
      divElement.scrollIntoView({ behavior: "smooth" });
    }
  }, [currentSectionId]);
  if (!loading) {
    return (
      <Box className={appStyles.container}>
        <SectionScroller
          handleUp={handleScrollUp}
          handleDown={handleScrollDown}
        />
        <ThemeSwitch />
        <div className={`${appStyles.section} ${appStyles.home}`} id="home">
          <HomeComponent />
        </div>
        <div className={appStyles.parallax}></div>

        <Container
          maxWidth="lg"
          className={`${appStyles.section} ${appStyles.journey}`}
          id="journey"
        >
          <JourneyComponent data={journeyData} />
        </Container>
        <Box
          className={`${appStyles.section} ${
            theme.palette.mode === "dark"
              ? `${appStyles.project_dm}`
              : `${appStyles.project_lm}`
          }`}
          id="project"
        >
          <ProjectComponent data={projectsData} />
        </Box>
        <Container
          maxWidth="lg"
          className={`${appStyles.section} ${appStyles.life}`}
          id="life"
        >
          <LifeComponent data={lifeData} />
        </Container>
        <Box
          className={`${appStyles.section} ${
            theme.palette.mode === "dark"
              ? `${appStyles.core_dm}`
              : `${appStyles.core_lm}`
          }`}
          id="core"
        >
          <CoreComponent />
        </Box>
        {/* <Container maxWidth="lg" className={`${appStyles.about}`} id="about">
          <AboutComponent />
        </Container> */}
        {/* <Container maxWidth="lg" className={`${appStyles.about}`} id="hobbies">
          <HobbiesComponent />
        </Container> */}
        <Box
          className={`${appStyles.footer} ${
            theme.palette.mode === "dark"
              ? `${appStyles.footer_dm}`
              : `${appStyles.footer_lm}`
          }`}
          id="footer"
        >
          <FooterComponent />
        </Box>
      </Box>
    );
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100vh ",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          backgroundColor:
            theme.palette.mode === "dark" ? "#121212" : "#e2e2e2",
        }}
      >
        <CircularProgress size="7rem" />
      </div>
    );
  }
}

export default App;
