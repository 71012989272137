import { useTheme } from "@emotion/react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import journeyStyles from "./journey-component.module.css";

import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { motion } from "framer-motion";
const JourneyComponent = ({ data }) => {
  const theme = useTheme();

  const appearLeftVariants = {
    offscreen: {
      opacity: 0,
    },
    onscreen: {
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0,
        duration: 2,
      },
    },
  };

  return (
    <Box className={journeyStyles.container}>
      <motion.div
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.4 }}
        variants={appearLeftVariants}
        className={journeyStyles.heading__container}
      >
        <Box className={journeyStyles.line} />
        <Typography variant="h2" className={`${journeyStyles.heading}`}>
          This Is My Adventure, My Path
        </Typography>
        <Box className={journeyStyles.line} />
      </motion.div>

      <Typography className={`${journeyStyles.sub__heading}`}>
        I'm a US-based{" "}
        <span
          id={
            theme.palette.mode === "dark"
              ? `${journeyStyles["span_dm"]}`
              : `${journeyStyles["span_lm"]}`
          }
        >
          entrepreneur
        </span>
        ,{" "}
        <span
          id={
            theme.palette.mode === "dark"
              ? `${journeyStyles["span_dm"]}`
              : `${journeyStyles["span_lm"]}`
          }
        >
          multihyphenate creator
        </span>
        , and{" "}
        <span
          id={
            theme.palette.mode === "dark"
              ? `${journeyStyles["span_dm"]}`
              : `${journeyStyles["span_lm"]}`
          }
        >
          innovator
        </span>{" "}
        with goal oriented vision prioritizing the customer and relationship
        focusing on movements that generate resilience, self-sustainability, and
        dynamism.
      </Typography>

      {data.map((item, idx) => {
        return (
          <Timeline
            key={idx}
            sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
              padding: 0,
            }}
          >
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot sx={{ backgroundColor: "#3377cc" }}>
                  <UnfoldMoreIcon fontSize="large" />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ padding: 0, boxShadow: 0 }}>
                <Grid container className={journeyStyles.grid}>
                  <Grid
                    item
                    xl={3}
                    lg={3}
                    md={3}
                    sm={12}
                    xs={12}
                    className={journeyStyles.imageGrid}
                  >
                    <div
                      style={{
                        borderRadius: "30px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <a href={item.project_url} target="_blank">
                        <img
                          className={journeyStyles.image}
                          style={{
                            filter:
                              item.heading == "Intelligene/CG Capital"
                                ? "drop-shadow(2px 0px 0px #87CEEB) drop-shadow(-2px 0px 0px #87CEEB)"
                                : item.heading == "Vicunha USA"
                                ? " invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%)"
                                : "none",
                          }}
                          src={item.image}
                          alt={item.heading}
                        />
                      </a>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xl={9}
                    lg={9}
                    md={9}
                    sm={12}
                    xs={12}
                    className={journeyStyles.contentGrid}
                  >
                    <Accordion sx={{ padding: 0, boxShadow: 0 }}>
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon
                            sx={{
                              // backgroundColor: "#579401",
                              fontSize: "60px",
                              borderRadius: "20px",
                              color: "#3377cc",
                            }}
                          />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{ borderRadius: "20px" }}
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Typography
                            id={
                              theme.palette.mode === "dark"
                                ? `${journeyStyles["heading_dm"]}`
                                : `${journeyStyles["heading_lm"]}`
                            }
                          >
                            {item.heading}
                          </Typography>
                          <Typography
                            id={
                              theme.palette.mode === "dark"
                                ? `${journeyStyles["position_dm"]}`
                                : `${journeyStyles["position_lm"]}`
                            }
                          >
                            {item.position}
                          </Typography>

                          <Typography
                            id={
                              theme.palette.mode === "dark"
                                ? `${journeyStyles["address_dm"]}`
                                : `${journeyStyles["address_lm"]}`
                            }
                          >
                            {item.address}
                          </Typography>
                          <Typography
                            id={
                              theme.palette.mode === "dark"
                                ? `${journeyStyles["time_dm"]}`
                                : `${journeyStyles["time_lm"]}`
                            }
                          >{`${item.start_date} - ${item.end_date}`}</Typography>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box className={journeyStyles.paragraph__container}>
                          {item.paragraphs.map((singleParagraph, idx) => {
                            return (
                              <Typography
                                key={idx}
                                dangerouslySetInnerHTML={{
                                  __html: singleParagraph,
                                }}
                                className={
                                  theme.palette.mode === "dark"
                                    ? `${journeyStyles.paragraph_dm}`
                                    : `${journeyStyles.paragraph_lm}`
                                }
                              />
                            );
                          })}
                        </Box>
                        <Typography
                          style={{ padding: "0px 20px" }}
                          id={
                            theme.palette.mode === "dark"
                              ? `${journeyStyles["achievements_dm"]}`
                              : `${journeyStyles["achievements_lm"]}`
                          }
                        >
                          Achievements
                        </Typography>

                        <ul className={journeyStyles.list__container}>
                          {item.list.map((single, idx) => {
                            return (
                              <li>
                                <Box
                                  sx={{
                                    "&:hover": {
                                      transition: "all 250ms linear",
                                      cursor: "pointer",
                                      transform: "scale(1.01)",
                                    },
                                  }}
                                >
                                  <Typography
                                    id={
                                      theme.palette.mode === "dark"
                                        ? `${journeyStyles["achievementText_dm"]}`
                                        : `${journeyStyles["achievementText_lm"]}`
                                    }
                                    key={idx}
                                    dangerouslySetInnerHTML={{
                                      __html: single,
                                    }}
                                    className={journeyStyles.list__item}
                                  />
                                </Box>
                              </li>
                            );
                          })}
                        </ul>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Grid>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        );
      })}
    </Box>
  );
};

export default JourneyComponent;
