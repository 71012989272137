import React from "react";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import { CssBaseline } from "@mui/material";

import { amber, deepOrange, grey } from "@mui/material/colors";

export const ColorModeContext = React.createContext({
  toggleColorMode: () => {},
});

const ThemeWrapper = ({ children }) => {
  const [mode, setMode] = React.useState("light");

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        console.log("Chaning theme to", !mode);
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          primary: {
            ...amber,
            ...(mode === "dark" && {
              main: amber[300],
            }),
          },
          ...(mode === "dark" && {
            background: {
              default: "#121212",
              paper: "#121212",
            },
          }),
          ...(mode === "light" && {
            background: {
              default: "#f5f5f5",
              paper: "#f5f5f5",
            },
          }),
          text: {
            ...(mode === "light"
              ? {
                  primary: grey[900],
                  secondary: grey[800],
                }
              : {
                  primary: "#fff",
                  secondary: grey[500],
                }),
          },
        },
        typography: {
          fontFamily: ["Raleway", "sans-serif"].join(","),
          h1: {
            fontWeight: 600,
            fontSize: "5rem",
            lineHeight: 1.2,
          },
          h2: {
            fontWeight: 600,
            fontSize: "2rem",
            lineHeight: 1.2,
          },
          h3: {
            fontWeight: 600,
            fontSize: "1.5rem",
            lineHeight: 1.2,
          },
          h3__italic: {
            fontWeight: 600,
            fontSize: "1.5rem",
            lineHeight: 1.2,
            fontStyle: "italic",
            textAlign: "center",
          },
        },
      }),
    [mode]
  );
  // responsiveFontSizes(theme);
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default ThemeWrapper;
