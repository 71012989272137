import { useTheme } from "@emotion/react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import lifeStyles from "./life-component.module.css";

const LifeComponent = ({ data }) => {
  const [readMore, setReadMore] = useState(false);
  const theme = useTheme();
  console.log("all life data", data);
  const descriptions = [
    "My Muse and my inspiration to be a husband and a father. Since the day she met me and call her mom that night and said “I met the man I am going to marry” what an incredible journey full of action, romance, comedy, and drama. What a perfect imperfect life. I would do it all over again.",
    "The wild child. Never gets tired, always going, and willing to try any and every activity that challenges gravity.",
    "The rock of the family. Focus determined and never gives up.",
    "The one that makes us laugh. He is the comedian, the dancer, the athletic.",
  ];

  return (
    <Box className={lifeStyles.container}>
      <Box className={lifeStyles.heading__container}>
        <Box
          className={lifeStyles.line}
          sx={{ backgroundColor: theme.palette.background }}
        />
        <Typography variant="h2" className={`${lifeStyles.heading}`}>
          My Life
        </Typography>

        <Box className={lifeStyles.line} />
      </Box>
      <Typography id={lifeStyles["desc"]}>
        In my life, the people I love make all the difference.
        <span
          style={
            theme.palette.mode === "dark"
              ? { color: "#3377cc" }
              : { color: " #3377cc" }
          }
        >
          {" "}
          Meet my daily Muses
        </span>
      </Typography>
      <Container maxWidth="lg">
        <Grid
          container
          className={lifeStyles.grid}
          spacing={2}
          justifyContent="center"
        >
          {data.map((person, idx) => {
            return (
              <Grid
                key={idx}
                item
                xl={3}
                lg={3}
                md={3}
                sm={6}
                xs={12}
                className={lifeStyles.contentGrid}
              >
                <Box
                  className={
                    theme.palette.mode === "dark"
                      ? lifeStyles.card__dm
                      : lifeStyles.card__lm
                  }
                  sx={{ "&:hover": { cursor: "pointer" } }}
                >
                  <img
                    className={lifeStyles.image}
                    src={require(`../../assets/${person.image}`)}
                    alt={person.name}
                  />
                  <Typography
                    style={
                      theme.palette.mode === "dark"
                        ? {
                            color: "#3377cc",
                            fontWeight: "bold",
                            fontSize: "1.1rem",
                          }
                        : {
                            color: " #3377cc",
                            fontWeight: "bold",
                            fontSize: "1.1rem",
                          }
                    }
                  >
                    {person.name}
                  </Typography>
                  {descriptions[idx].length < 115 ? (
                    <Typography
                      style={
                        theme.palette.mode === "dark"
                          ? {
                              color: "#87ceeb",
                              fontWeight: "normal",
                              fontStyle: "italic",
                              fontSize: "1rem",
                            }
                          : {
                              color: " #275466",
                              fontWeight: "normal",
                              fontStyle: "italic",
                              fontSize: "1rem",
                            }
                      }
                    >
                      {descriptions[idx]}
                    </Typography>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        style={
                          theme.palette.mode === "dark"
                            ? {
                                color: "#87ceeb",
                                fontWeight: "normal",
                                fontStyle: "italic",
                                fontSize: "1rem",
                              }
                            : {
                                color: " #275466",
                                fontWeight: "normal",
                                fontStyle: "italic",
                                fontSize: "1rem",
                              }
                        }
                      >
                        {!readMore
                          ? `${descriptions[idx].slice(0, 80)}...`
                          : `${descriptions[idx]}`}
                      </Typography>
                      {!readMore ? (
                        <Button
                          onClick={() => setReadMore(true)}
                          sx={{ color: "#000" }}
                        >
                          Read More
                        </Button>
                      ) : null}
                      {readMore ? (
                        <Button
                          onClick={() => setReadMore(false)}
                          sx={{ color: "#000" }}
                        >
                          Read Less
                        </Button>
                      ) : null}
                    </div>
                  )}
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </Box>
  );
};

export default LifeComponent;
