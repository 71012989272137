import { useTheme } from "@emotion/react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import projectStyles from "./project-component.module.css";

import ProjectCard from "./project-card/ProjectCard";
const ProjectComponent = ({ data }) => {
  const theme = useTheme();

  return (
    <Box className={projectStyles.container}>
      <Box className={projectStyles.heading__container}>
        <Box
          className={projectStyles.line}
          sx={{ backgroundColor: theme.palette.background }}
        />
        <Typography variant="h2" className={`${projectStyles.heading}`}>
          My Projects
        </Typography>
        <Box className={projectStyles.line} />
      </Box>
      <Container maxWidth="lg">
        <Grid
          container
          className={projectStyles.grid}
          rowGap={3}
          justifyContent="center"
        >
          {data.map((project, idx) => {
            return (
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={6}
                xs={12}
                sx={{ padding: "0px 20px" }}
              >
                <ProjectCard data={project} key={idx} />
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </Box>
  );
};

export default ProjectComponent;
